@import './variables';

.footerWrapper{

  display: flex;
  align-items: center;

  margin-top: 50px;
  background-color: $footer-background-color;
  min-height: 170px;
  border-top: .5px solid $footer-border-color;

  color: $footer-color;

  .footerContent{
    margin: auto;
    width: 70%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.footerWrapper p {
  padding: 10px;
}

.footerImage{
  margin: auto;
  margin-bottom: 5px;
  width: 50%;
  min-width: 250px;
  max-width: 700px;
}