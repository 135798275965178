.startPageImageContainer{
  display: flex;
  margin: 10px auto;
  width: 90%;
}

.startPageImage{
  margin: auto;
  width: 75%;
  max-width: 700px;
}

.sociolinguisticsImage{
  width: 100%;
}

.logo{
  position: absolute;
  left: 7px;
  width: 100px;
  height: 100px;
}

.about{
  margin: auto auto 10px;
  width: 50%;
  font-size: 20px;
}

.figure-text{
  color: #666;
}

@media only screen and (max-width: 900px) {
  .about{
    width: 70%;
  }
}

.example_div{
  position: fixed;
  background-color:black;
  width: 50px;
  height: 50px;
}

.playAudio{
  cursor: pointer;
  color: red;
}

a{
  overflow-wrap: break-word;
}