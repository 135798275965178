@import './../variables';


.informantInfoPanel{
  margin-left: 20px;
  width: 32%;
  min-width: 350px;

  height: $text-window-content-height;
  max-height: $text-window-content-height;

  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;

  h2, .infoText, .secondInfPanel{
    min-width: 300px;
  }
}

.secondInfPanel{
  cursor: pointer;
  width: 90%;
  background: $second-inf-background;
  border-radius: 5px;
  border: 1px solid $second-inf-border-color;
  padding: 10px;
  margin-top: 15px;
  min-width: 280px;

  margin-bottom: 0;
}

.infoText{
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;

  span{
    width: 100%;
    margin: 2px 0;
  }
}

.informantInfoPanelAnimationStart{
  width: 0%;
  min-width: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.informantInfoPanelAnimationEnd{
  width: 32%;
  min-width: 350px;
}

.secondInfAnimationStart{
  height: 0px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.secondInfAnimationEnd{
  height: 203px;
}

