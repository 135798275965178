body{
  margin: 0;
}

.main{
  margin: auto;
  width: 80%;
}

@media only screen and (max-width: 1100px) {
  .main{
    margin: 0 0 10px 10px;
    width: 100%;
  }
}