@import './../variables';

.textWindowBackground{
  position: fixed;
  top: 0;

  visibility: visible;

  width: 100%;
  height: 100%;
  z-index: 100;

  background: rgba(34, 34, 34, 0.75);
}

.textWindowWrapper{
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  
  min-width: 1000px;
  width: 65%;
  height: 80%;
  max-height: 662px;

  background-color: $wrapper-background-color;
  border-radius: 5px;
  box-shadow: 0 0 1px $box-shadow-color-dark;
}

.textWindowHeader{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 30px;
}

.textWindowContent{
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex: 1;
  max-height: $text-window-content-height;
  overflow: auto;
}

.textWindowFooter{
  display: flex;
  align-items: center;

  width: 95%;
  margin: auto;

  audio{
    width: 100%;
    margin: auto;
    padding: 10px;
  }
}

/* ____ Text ____ */

.text{
  // margin: 10px 0 10px 10px;

  /*display: flex;*/
  /*flex-wrap: nowrap;*/
  justify-content: space-around;

  // min-width: 650px;
  width: 100%;
  // max-height: $text-window-content-height;
  height: $text-window-content-height;

  overflow: auto;
}

.text div{
  margin: 3px 0;
}

.closeButton{
  margin: 2px;
  display: flex;
  align-self: flex-end;

  height: 27px;

  padding: 5px 25px;
  background: $close-button-background-color;
  border-radius: 2px;
  border: 1px solid $close-button-border-color;
  cursor: pointer;

  img{
    width: 15px;
  }
}

.closeButton:hover{
  background: $close-button-background-color-hover;
}

.deleteButton{

  cursor: pointer;
  border: none;
  background-color: $wrapper-background-color;

  img{
    width: 25px;
  }
}

/* ____ Informant Panel Hide Button ____ */
.informantInfoPanelHideButton{
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin: auto;
  margin-right: 10px;

  height: $text-window-content-height;

  background-color: $wrapper-background-color;
  // background-color: #fbfbfb;
  border: none;
  visibility: hidden;
}

.informantInfoPanelHideButton:hover{
  cursor: pointer;
  background-color: #eee;
}

@media only screen and (max-width: 1025px) {
  
  .textWindowWrapper{
    min-width: 99%;
  }

  .informantInfoPanelHideButton{
    visibility: visible;
  }
}