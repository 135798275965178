@import './../variables';

.selectWrapper{
  position: relative;
  margin: 20px 0;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  min-width: 1016px;

  background: $wrapper-background-color;
  box-shadow: 0 0 5px $box-shadow-color;
  border-radius: 5px;
}

.selectItem{
  margin: 10px;
  width: 25%;
  min-width: 150px;
}