@import './../variables';

table{
    margin: 10px auto;
    width: 100%;
    max-height: 50px;

    border-radius: 5px;
    background: $table-background-color;

    overflow: auto;
}

thead{
    background: $table-background-color;
}

.row{
    background: $row-background-color;
    cursor: pointer;
    text-align: center;
}

.row:hover{
    background: $row-background-color-hover;
}

th, td{
    padding: 10px 10px;
    min-width: 10%;
}

th:hover{
    cursor: pointer;
    background: $table-header-background-color-hover;
}

.notFoundDiv{
    position: relative;
    top: -15px;

    display: flex;
    justify-content: center;
    align-content: center;

    margin: auto;
    min-height: 50px;

    background: $row-background-color;
    border: 2px solid $table-background-color;

    overflow: auto;
}

.fade-enter{
    opacity: 0;
}

.fade-enter-active{
    opacity: 1;
    transition: opacity 300ms ease-out;
}

.fade-leave{
    opacity: 1;
}

.fade-leave-active{
    opacity: 0;
    transition: opacity 300ms ease-out;
}