@import './../variables';

.checkboxSearchWrapper{
    display: flex;
    flex-wrap: wrap;

    position: relative;
    left: 15px;

}
 
.checkboxItem{
    margin: 10px;  
    width: 18%; 
    min-width: 160px;
}

.childCheckbox{
    position: relative;
    left: 12px;

    label, input{
        cursor: pointer;
    }
}

.traitsSelection{
    margin: 10px 10px;

    background: $wrapper-background-color;
    box-shadow: 0 0 5px $box-shadow-color;
    border-radius: 5px;
}