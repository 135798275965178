@import './../variables';

.popUpWrapper{
    position: fixed; 

    min-width: 230px;
    min-height: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: $popup-background-color;
    border-radius: 5px;

    box-shadow: 0 0 1px $box-shadow-color-dark;
}

#closePopUpIcon{
    position: absolute;
    right: 0;

    padding: 3px 15px;
    background: $close-button-background-color;
    border: 1px solid $close-button-border-color;
    border-radius: 2px;
    cursor: pointer;
    color: $close-button-text-color;
}

#closePopUpIcon:hover{
    background: $close-button-background-color-hover;
}

.popUpButtonContainer{
    display: flex;
    justify-content: space-around;

    width: 100%;
}

.popUpButtonContainer button{
    width: 50%;
    height: 30px;
}

#btnA{
    width: 100%;
    height: 30px;
}

.defaultButton{
    background-color: $popup-default-button-background;
    border: 1px solid $popup-default-button-border-color;
}

.defaultButton:Hover{
    background-color: $popup-default-button-background-hover;
    cursor: pointer;
}

.activeButton{
    background-color: $popup-active-button-background;
    border: 1px solid $popup-default-button-border-color;
}

.activeButton:Hover{
    background-color: $popup-default-button-border-color; 
    cursor: pointer;
}