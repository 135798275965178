.startPageImageContainer {
  display: flex;
  margin: 10px auto;
  width: 90%; }

.startPageImage {
  margin: auto;
  width: 75%;
  max-width: 700px; }

.sociolinguisticsImage {
  width: 100%; }

.logo {
  position: absolute;
  left: 7px;
  width: 100px;
  height: 100px; }

.about {
  margin: auto auto 10px;
  width: 50%;
  font-size: 20px; }

.figure-text {
  color: #666; }

@media only screen and (max-width: 900px) {
  .about {
    width: 70%; } }

.example_div {
  position: fixed;
  background-color: black;
  width: 50px;
  height: 50px; }

.playAudio {
  cursor: pointer;
  color: red; }

a {
  overflow-wrap: break-word; }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.selectWrapper {
  position: relative;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 1016px;
  background: #fafafa;
  box-shadow: 0 0 5px #888;
  border-radius: 5px; }

.selectItem {
  margin: 10px;
  width: 25%;
  min-width: 150px; }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.informantInfoPanel {
  margin-left: 20px;
  width: 32%;
  min-width: 350px;
  height: 540px;
  max-height: 540px;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap; }
  .informantInfoPanel h2, .informantInfoPanel .infoText, .informantInfoPanel .secondInfPanel {
    min-width: 300px; }

.secondInfPanel {
  cursor: pointer;
  width: 90%;
  background: #e6e6e6;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  margin-top: 15px;
  min-width: 280px;
  margin-bottom: 0; }

.infoText {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap; }
  .infoText span {
    width: 100%;
    margin: 2px 0; }

.informantInfoPanelAnimationStart {
  width: 0%;
  min-width: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

.informantInfoPanelAnimationEnd {
  width: 32%;
  min-width: 350px; }

.secondInfAnimationStart {
  height: 0px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.secondInfAnimationEnd {
  height: 203px; }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.popUpWrapper {
  position: fixed;
  min-width: 230px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dbdbdb;
  border-radius: 5px;
  box-shadow: 0 0 1px #222; }

#closePopUpIcon {
  position: absolute;
  right: 0;
  padding: 3px 15px;
  background: #ff122d;
  border: 1px solid #ec5f47;
  border-radius: 2px;
  cursor: pointer;
  color: #fafafa; }

#closePopUpIcon:hover {
  background: #ff3c52; }

.popUpButtonContainer {
  display: flex;
  justify-content: space-around;
  width: 100%; }

.popUpButtonContainer button {
  width: 50%;
  height: 30px; }

#btnA {
  width: 100%;
  height: 30px; }

.defaultButton {
  background-color: #F1F1F1;
  border: 1px solid #BBB; }

.defaultButton:Hover {
  background-color: #E6E6E6;
  cursor: pointer; }

.activeButton {
  background-color: #c5c5c5;
  border: 1px solid #BBB; }

.activeButton:Hover {
  background-color: #BBB;
  cursor: pointer; }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.deleteLocalStorageWrapper {
  position: relative;
  display: inline-block; }

.deleteLocalStorage {
  display: block;
  position: absolute;
  right: 5px;
  top: -75px;
  z-index: 101;
  width: 165px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #fafafa;
  box-shadow: 5px 8px 16px 0px #888; }
  .deleteLocalStorage button {
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    background-color: #fafafa;
    text-align: left; }
  .deleteLocalStorage button:hover {
    background-color: #ddd; }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.textWindowBackground {
  position: fixed;
  top: 0;
  visibility: visible;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(34, 34, 34, 0.75); }

.textWindowWrapper {
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  min-width: 1000px;
  width: 65%;
  height: 80%;
  max-height: 662px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0 0 1px #222; }

.textWindowHeader {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 30px; }

.textWindowContent {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex: 1 1;
  max-height: 540px;
  overflow: auto; }

.textWindowFooter {
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto; }
  .textWindowFooter audio {
    width: 100%;
    margin: auto;
    padding: 10px; }

/* ____ Text ____ */
.text {
  /*display: flex;*/
  /*flex-wrap: nowrap;*/
  justify-content: space-around;
  width: 100%;
  height: 540px;
  overflow: auto; }

.text div {
  margin: 3px 0; }

.closeButton {
  margin: 2px;
  display: flex;
  align-self: flex-end;
  height: 27px;
  padding: 5px 25px;
  background: #ff122d;
  border-radius: 2px;
  border: 1px solid #ec5f47;
  cursor: pointer; }
  .closeButton img {
    width: 15px; }

.closeButton:hover {
  background: #ff3c52; }

.deleteButton {
  cursor: pointer;
  border: none;
  background-color: #fafafa; }
  .deleteButton img {
    width: 25px; }

/* ____ Informant Panel Hide Button ____ */
.informantInfoPanelHideButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-right: 10px;
  height: 540px;
  background-color: #fafafa;
  border: none;
  visibility: hidden; }

.informantInfoPanelHideButton:hover {
  cursor: pointer;
  background-color: #eee; }

@media only screen and (max-width: 1025px) {
  .textWindowWrapper {
    min-width: 99%; }
  .informantInfoPanelHideButton {
    visibility: visible; } }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
table {
  margin: 10px auto;
  width: 100%;
  max-height: 50px;
  border-radius: 5px;
  background: #f1f1f1;
  overflow: auto; }

thead {
  background: #f1f1f1; }

.row {
  background: #fefefe;
  cursor: pointer;
  text-align: center; }

.row:hover {
  background: #f1f1f1; }

th, td {
  padding: 10px 10px;
  min-width: 10%; }

th:hover {
  cursor: pointer;
  background: #ddd; }

.notFoundDiv {
  position: relative;
  top: -15px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  min-height: 50px;
  background: #fefefe;
  border: 2px solid #f1f1f1;
  overflow: auto; }

.fade-enter {
  opacity: 0; }

.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms ease-out;
  transition: opacity 300ms ease-out; }

.fade-leave {
  opacity: 1; }

.fade-leave-active {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-out;
  transition: opacity 300ms ease-out; }

body {
  margin: 0; }

.main {
  margin: auto;
  width: 80%; }

@media only screen and (max-width: 1100px) {
  .main {
    margin: 0 0 10px 10px;
    width: 100%; } }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.checkboxSearchWrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: 15px; }

.checkboxItem {
  margin: 10px;
  width: 18%;
  min-width: 160px; }

.childCheckbox {
  position: relative;
  left: 12px; }
  .childCheckbox label, .childCheckbox input {
    cursor: pointer; }

.traitsSelection {
  margin: 10px 10px;
  background: #fafafa;
  box-shadow: 0 0 5px #888;
  border-radius: 5px; }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.radioButtonsWrapper {
  margin: 10px auto;
  margin-top: 20px;
  width: 80%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fefefe;
  box-shadow: 0 0 5px #888;
  border-radius: 5px; }
  .radioButtonsWrapper .radioButtonsContent {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  .radioButtonsWrapper label {
    margin: 20px;
    padding: 5px;
    cursor: pointer; }
    .radioButtonsWrapper label input {
      cursor: pointer; }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.graphPage {
  margin: auto;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.graph {
  width: 49%;
  min-width: 300px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 5px #888; }

.graphLabel {
  margin: 0;
  padding: 5px 0 5px 5px;
  width: 100%;
  background-color: #fafafa;
  box-shadow: 0 1px #ddd;
  font-weight: bold;
  /* Safari/Chrome, other WebKit */
  /* Firefox, other Gecko */
  box-sizing: border-box; }

.graphSearchArea {
  margin: 10px auto;
  width: 80%;
  overflow: hidden;
  background: #fefefe;
  box-shadow: 0 0 5px #888;
  border-radius: 5px; }

.graphColorExplanation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 -10px 0; }
  .graphColorExplanation .colorExplanation {
    width: 20px;
    height: 20px;
    margin: 0 4px 0 15px; }
  .graphColorExplanation .explanation1 {
    background-color: #4AACC5; }
  .graphColorExplanation .explanation2 {
    background-color: #77a033; }
  .graphColorExplanation .explanation3 {
    background-color: #e07676; }

@media all and (max-width: 820px) {
  .graph {
    width: 100%;
    min-width: 300px; } }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.logo-navbar {
  display: flex;
  justify-content: space-around;
  width: 100%;
  box-shadow: 1px 1px 1px #bebebe; }

.logo-navbar-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px; }
  .logo-navbar-images .uib-logo {
    width: 75px; }
  .logo-navbar-images .dialektbasen-logo {
    width: 175px; }
  .logo-navbar-images .logo-divider {
    margin: auto 15px;
    width: 2px;
    min-height: 10px;
    height: 60%;
    background-color: #eee; }

.logo-navbar-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff; }
  .logo-navbar-items .item {
    margin: 15px 15px 0 15px;
    text-align: center;
    height: 25px;
    text-decoration: none;
    font-weight: bold;
    color: #333; }
  .logo-navbar-items .item:hover {
    color: #6780ca; }
  .logo-navbar-items .active {
    border-bottom: 2px solid #7C98ED;
    color: #5071d4; }

@media only screen and (max-width: 745px) {
  .logo-navbar-images {
    visibility: hidden;
    width: 0; }
  .logo-navbar-items {
    width: 100%; } }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
.footerWrapper {
  display: flex;
  align-items: center;
  margin-top: 50px;
  background-color: #dee2f6;
  min-height: 170px;
  border-top: 0.5px solid #a5b8f5;
  color: #444; }
  .footerWrapper .footerContent {
    margin: auto;
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.footerWrapper p {
  padding: 10px; }

.footerImage {
  margin: auto;
  margin-bottom: 5px;
  width: 50%;
  min-width: 250px;
  max-width: 700px; }

.errorPage {
  margin: 10px auto;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .errorPage img {
    width: 50%;
    min-width: 250px; }

/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.app {
  min-height: 100vh;
  flex-direction: column;
  display: flex; }

.content {
  flex: 1 1; }

