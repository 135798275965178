@import './../variables';

.radioButtonsWrapper{

    margin: 10px auto;
    margin-top: 20px;
    width: 80%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    background-color: $graph-background-color;
    box-shadow: 0 0 5px $box-shadow-color;
    border-radius: 5px;

    .radioButtonsContent{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    label{
        //min-width: 5%;
        margin: 20px;
        padding: 5px;

        cursor: pointer;

        input{
            cursor: pointer;
        }
    }
}
