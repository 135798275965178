@import './../variables';

.graphPage{
    margin: auto;
    width: 80%;

    display: flex;
    flex-wrap: wrap;

    justify-content: space-between; 
}
    
.graph{
    width: 49%;
    min-width: 300px; 
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 0 5px $box-shadow-color;
}  
  
.graphLabel{ 
    margin: 0;
    padding: 5px 0 5px 5px;
    width: 100%;  
    background-color: $graph-label-background-color;
    box-shadow: 0 1px $box-shadow-color-light;
    font-weight: bold;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}

.graphSearchArea{
    margin: 10px auto;
    width: 80%;
    overflow: hidden;

    background: $graph-background-color;
    box-shadow: 0 0 5px $box-shadow-color;
    border-radius: 5px;
}

.graphColorExplanation{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 10px 0 -10px 0;

    .colorExplanation{
        width: 20px;
        height: 20px;
        margin: 0 4px 0 15px;
    }

    .explanation1{
        background-color: $explanation-color-1;
    }

    .explanation2{
        background-color: $explanation-color-2;
    }

    .explanation3{
        background-color: $explanation-color-3;
    }
}


@media all and (max-width: 820px)  {
    .graph{
        width: 100%;
        min-width: 300px;
    }
}