/* _______ General _______ */
/* _______ Navbar _______ */
/* _______ Footer _______ */
/* _______ PopUp _______ */
/* _______ Database _______ */
/* _______ Graph _______ */
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.app {
  min-height: 100vh;
  flex-direction: column;
  display: flex; }

.content {
  flex: 1; }
