/* _______ General _______ */
$main-text-color: #333;
$wrapper-background-color: #fafafa;

$close-button-background-color: #ff122d;
$close-button-background-color-hover: #ff3c52;
$close-button-border-color: #ec5f47;
$close-button-text-color: #fafafa;

$box-shadow-color: #888;
$box-shadow-color-light: #ddd;
$box-shadow-color-dark: #222;

/* _______ Navbar _______ */
$navbar-hover-color: #6780ca;
$navbar-active-border-color: #7C98ED;
$navbar-active-color: #5071d4;
$navbar-background-color: #fff;
$navbar-shadow-color: #bebebe;

/* _______ Footer _______ */
$footer-color: #444;
$footer-background-color: #dee2f6;
$footer-border-color: #a5b8f5;

/* _______ PopUp _______ */
$popup-background-color: #dbdbdb;
$popup-active-button-background: #c5c5c5;

$popup-default-button-border-color: #BBB;
$popup-default-button-background: #F1F1F1;
$popup-default-button-background-hover: #E6E6E6;

/* _______ Database _______ */
$table-background-color: #f1f1f1;//#e4e4e4;
$table-header-background-color-hover: #ddd;
$row-background-color: #fefefe;
$row-background-color-hover: #f1f1f1;

$second-inf-background: #e6e6e6;
$second-inf-border-color: #e6e6e6;

$text-window-content-height: 540px;

/* _______ Graph _______ */
$explanation-color-1: #4AACC5;
$explanation-color-2: #77a033;
$explanation-color-3: #e07676;

$graph-background-color: #fefefe;
$graph-label-background-color: #fafafa;